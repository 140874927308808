<!-- Version: 1.0 -->
<template>
  <b-row class="eh-table-list-paginator-container" no-gutters>
    <b-col lg="9">
      <b-pagination
        v-model="currentPage"
        :total-rows="itemsCount"
        :per-page="perPage"
        align="fill"
        variant="eh-primary-color"
        class="eh-table-list-paginator"
      >
        <template #page="{ page, active }">
          <div class="eh-paginator-page-active" v-if="active">
            {{ page }}
          </div>
          <div class="eh-paginator-page-inactive" v-else>{{ page }}</div>
        </template>
      </b-pagination>
    </b-col>
    <b-col lg="3">
      <div style="justify-content: flex-end; flex-wrap: nowrap; display: flex">
        <div class="eh-table-list-page-size-container">
          <fp-select
            class="eh-table-list-page-size-box"
            :items="[10, 25, 50, 75, 100]"
            v-model="perPage"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "Pagination",
  props: {
    itemsPerPage: Number,
    listSize: Number,
    currentPageNumber: Number,
  },
  data() {
    return {
      perPage: this.itemsPerPage,
      itemsCount: this.listSize,
      currentPage: this.currentPageNumber,
    };
  },
  watch: {
    itemsPerPage(input) {
      this.perPage = input;
    },
    perPage(input) {
      this.$emit("update:itemsPerPage", input);
    },
    listSize(input) {
      this.itemsCount = input;
    },
    itemsCount(input) {
      this.$emit("update:listSize", input);
    },
    currentPageNumber(input) {
      this.currentPage = input;
    },
    currentPage(input) {
      this.$emit("update:currentPageNumber",input);
    },
  },
};
</script>
